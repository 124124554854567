@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
    display: none;
}
body {
  overflow-x: hidden;
}





.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

.mask{
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('./assets/headerImg.jpg');
  background-size: cover;
  background-position: center;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium inline-block p-2 shadow-xl uppercase bg-gradient-to-r from-sky-400 to-blue-600;
  }
  .btn:hover {
    @apply bg-gradient-to-l from-sky-400 to-blue-600 text-gray-100 shadow-sm ease-in duration-300;
  }
  .btn-outline{
    @apply border-2 p-2 inline-block uppercase border-sky-600 bg-none;
  }
  .btn-outline:hover{
    @apply bg-gradient-to-r from-sky-400 to-blue-600 border-sky-600 ease-in duration-300;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r from-sky-600 to-blue-600 text-transparent bg-clip-text  font-bold font-primary;
  }
  .text-gradient:hover{
    @apply bg-gradient-to-l from-sky-600 to-blue-600 ease-in duration-300;
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
  .icon-color{
    @apply text-sky-400 hover:text-blue-600;
  }
  .text-blue-500{
    @apply hover:text-gradient;
  }
  .menuNav{
    @apply  text-sky-200 hover:text-blue-600
  }
  .menuNavbar{
    @apply  hover:text-blue-600
  }
  .icolink{
    @apply hover:bg-gradient-to-r from-sky-400 to-blue-600 hover:text-white
  }
}
